import React from 'react'
import { NavElement } from '../../components/home/common/navElements'
import LogoType from '../../images/v2/logotype.svg'
import PersonIcon from '../../images/desktop/home/navbar-person.svg'
import { Burger } from './burger'

export class Navbar extends React.PureComponent {
  state = {
    open: false,
    scrollY: 0,
    active: 0
  }

  componentDidMount() {
    if ((navigator.userAgent.indexOf('MSIE') != -1) || (!!document.documentMode === true)) {
      document.body.classList.add('isIE')
    }
    window.addEventListener('scroll', this.handleScroll, { passive: true })
    this.findActiveNavElement()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  findActiveNavElement = () => {
    const path = window.location.pathname
    if (path === '/accompagnement' || path === '/qui-sommes-nous') {
      this.setState({ active: 1 })
    } else if (path === '/promotion-immobiliere') {
      this.setState({ active: 2 })
    }
  }

  handleScroll = () => {
    const { scrollY } = this.state
    if (window.scrollY === 0 && scrollY) {
      this.setState({
        scrollY: false
      })
    } else if (window.scrollY > 0 && !scrollY) {
      this.setState({
        scrollY: true
      })
    }
  }

  handleClick = () => {
    this.setState(state => ({
      open: !state.open
    }))
  }

  handleClickCTA = (path) => {
    const { analytics } = window

    if (analytics) {
      analytics.track(`Navbar - Estimation - ${window.location.pathname}`)
    }
    window.location.assign(path)
  }

  render() {
    const { open, scrollY } = this.state
    const { empty = false } = this.props

    return (
      <div
        className={`${open ? 'is-open' : ''} nav-ctn ${scrollY ? 'is-shadow' : ''}`}
        style={{ background: empty ? 'transparent' : '#fff', zIndex: 1000 }}
        data-testid="nav-form"
      >
        <div className="container-navbar-closed" style={{ background: empty ? 'transparent' : '#fff' }}>
          <a href="/" id={empty ? 'exit-form' : ''}>
            <img src={LogoType} className="logo-navbar" alt="logo" />
          </a>
          <a className="phone-number-mobile" href="tel:+33184604442"><i className="fas fa-phone"></i><strong>01 84 60 44 42</strong></a>
          {window.innerWidth >= 980 && !empty
          && (
            <div className="menu-desktop">
              <NavElement active={this.state.active} />
              {!empty && <div className="ctn-btn-menu-open">
                <a className="phone-number-desktop" href="tel:+33184604442"><i className="fas fa-phone"></i><strong>01 84 60 44 42</strong></a>
                <CTAButton handleClick={(path) => this.handleClickCTA(path)} />
                <AccountButtons handleClick={() => handleClick()} />
              </div>}
            </div>
          )
          }
          {window.innerWidth < 980 && !empty && <Burger open={open} onClick={this.handleClick} />}
        </div>
        {open && (
          <div className="menu-mobile-open">
            {!empty && <NavElement handleClick={() => this.handleClick()} />}
            {!empty && (
              <div className="ctn-btn-menu-open">
                <button className="with-hover-red" onClick={() =>
                  window.location.assign('https://dashboard.cityandyou.com/')}>
                  <span>Mon compte</span>
                </button>
                <CTAButton handleClick={(path) => this.handleClickCTA(path)} />
                <AccountButtons />
              </div>
              )}
          </div>
        )}
      </div>
    )
  }
}

const AccountButtons = ({ handleClick }) => (
  <button
    className="account-button"
    onClick={() => handleClick()}
  >
    <img src={PersonIcon} alt="icône compte" />
    <div className="hidden-menu">
      <div className="hidden-menu-container">
        <a href="https://dashboard.cityandyou.com" rel="noopener noreferrer nofollow">Espace particulier</a>
        <a href="https://pro.cityandyou.com" rel="noopener noreferrer nofollow">Espace professionnel</a>
      </div>
    </div>
  </button>
)

const CTAButton = ({ handleClick }) => {
  const path = window.location.pathname
  let action, newPath
  if (path === '/estimation-immobiliere') {
    action = 'Estimer'
    newPath = '/estimation-immobiliere#valuation-home'
  } else if (path === '/simulation-projet') {
    action = 'Simuler'
    newPath = '/simulation-projet#simulation-home'
  } else {
    action = 'Simuler'
    newPath = '/simulation-projet#simulation-home'
  }
  return (
    <button
      className="with-bg-violet"
      onClick={() => handleClick(newPath)}
    >
      <span>{action}</span>
    </button>
  )
}


export default Navbar
